<template>
<div>
    <div class="adminHeaderWrapper">
        <div class="row adminHeader gx-2">
            <div class="col-md-6" v-for="day in week" :key="day.date">
                <div class="day-item mb-2">
                    <div class="day-item__date">{{$moment(day.date).format('ddd, DD.MM')}}</div>
                    <div class="day-item__btns">
                        <button class="btn-oval me-2" :class="{btnOrBr: day.off }" @click="dayOn(day.date)">Рабочий день</button>
                        <button class="btn-oval btn-oval--black-border" :class="{black: day.off }" @click="dayOff(day.date)">Нерабочий день</button>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-6">
                    <div class="day-item">
                        <div class="day-item__date">Пн, 6.12</div>
                        <div class="day-item__btns">
                        <button class="btn-oval me-2">Рабочий день</button>
                          <button class="btn-oval btn-oval--black">Нерабочий день</button>
                        </div>

                    </div>
                </div> -->
        </div>

    </div>
</div>
</template>

<script>
// import Navbar from '@/components/Navbar.vue'
// import AdminMenuHorizont from '@/components/AdminMenuHorizont.vue'

export default {

    data() {
        return {
            week: []
        };
    },
    components: {
        // Navbar,
        // AdminMenuHorizont,

    },
     head: {
        title: {
            inner: 'Рабочие дни',
            separator: '-',
            complement: 'Администратор'
        },
    },
    async mounted() {
        await this.$store.dispatch("actionGetDayOfWeek");

        this.generateDates()

    },
    methods: {
        async dayOff(date) {
            await this.$store.dispatch("actionAddDayOff", {
                date
            });
            await this.$store.dispatch("actionGetDayOfWeek");
            this.generateDates()

        },
        async dayOn(date) {
            await this.$store.dispatch("actionDeleteDayOff", {
                date
            });
            await this.$store.dispatch("actionGetDayOfWeek");

            this.generateDates()
        },
        generateDates() {
            this.week.length = 0
            for (let index = 0; index < 7; index++) {
                this.week.push({
                    date: this.$moment(new Date(new Date().setDate(new Date().getDate() + index))).format("YYYY-MM-DD"),
                    off: false
                })
            }
            this.week.forEach(element => {
                this.$store.state.admin.days.forEach(elementB => {
                    if (element.date == elementB.date) {
                        // alert(element.date)
                        element.off = true
                    }
                });

            });
        }
    }
}
</script>

<style lang="scss" scoped>
.adminHeaderWrapper {}

.container {}

.adminHeader {
    margin-top: 27px;
    margin-bottom: 40px;

    &__btns {}

    &__btn {
        display: flex;
        justify-content: end;
    }
}

.day-item {
    height: 141px;
    background: #F5F5F5;
    border-radius: 13px;
    padding: 28px 10px 20px 37px;

    &__date {
        line-height: 1;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
    }

    &__btns {}
}

.btn-oval {}

.container {
    max-width: 1340px;
}

.black {
    background: #000;
    color: #fff;
}
.btnOrBr{
    background: #fff;
    border:2px solid $orange;
    color:$orange;
}
</style>
